<script>
import MenuOptionsItem from "components/layout/context-menu/context-window-options-item"
import {UPopover} from "uloc-vue"
import TaskListPage from "components/suporte/components/task/ListPage"
import criarTarefaWindow from "components/suporte/components/task/window/criarTarefa";
export default {
  name: 'SuporteTarefas',
  inject: ['container'],
  mixins: [],
  components: {
    TaskListPage,
    MenuOptionsItem,
    UPopover
  },
  data () {
    return {
      routeName: ['suporte.tarefas'],
      listOptions: {
        loading: false,
        serverPagination: {
          page: 1,
          rowsNumber: 0,
          rowsPerPage: 20
        }
      }
    }
  },
  watch: {
    '$route.name' (v) {
      if (this.routeName.includes(v)) {
        this.$refs.list.load()
      }
    }
  },
  mounted() {
    if (this.routeName.includes(this.$route.name)) {
      this.$refs.list.load()
    }
  },
  computed: {
  },
  methods: {
    criarTarefaWindow,
    criarTarefa () {
      this.criarTarefaWindow(null, null)
    }
  },
  meta: {
    title: 'Tarefas',
    name: 'Tarefas'
  }
}
</script>

<template>
  <div>
  <div v-show="routeName.includes($route.name)">
    <ul class="snav">
      <li><a @click="$router.push({name: 'suporte.dashboard'})">Suporte</a></li>
      <li class="disable">Tarefas</li>
    </ul>
    <div class="stitle">
      <h2>Tarefas para Atendimento</h2>
      <div class="sopts">
        <u-btn @click="criarTarefa" label="Abrir nova solicitação" no-caps color="blue-8"/>
        <u-btn flat size="sm" class="m-l-xs">
          <i class="fa fa-ellipsis-h font-14" />
          <u-popover ref="popover" class="window-context-menu menu-profile min" anchor="bottom left" self="top left" :offset="[0, 15]" style="min-width: 172px">
            <div>
              <ul>
                <menu-options-item label="Configurações" close  />
              </ul>
            </div>
          </u-popover>
        </u-btn>
      </div>
    </div>
    <task-list-page ref="list" v-show="!container.loading" :autoload="false" />
  </div>
  <router-view />
  </div>
</template>
